<!-- 申请旗舰店 -->
<template>
  <div class="apply-container">
    <!--上级店铺信息-->
    <div class="superior-info" v-if="getShareAndPushData">
      <div class="cus-content">
        <img :src="getShareAndPushData.headImg" />
        <div class="user">
          <span>{{ getShareAndPushData.agentName }}</span>
          <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211223161750058.png" />

          <div class="phone">
            <span>邀请人：{{ getShareAndPushData.invitUser.agentName }}</span>
            <span>（{{ getShareAndPushData.invitUser.agentPho }}）</span>
            <van-icon name="phone" />
          </div>
        </div>
      </div>
    </div>
    <div class="main-title">开旗舰店</div>
    <van-cell-group>
      <van-cell title="选择品类" class="brand-box">
        <van-radio-group v-model="brandId" direction="horizontal" checked-color="#a771ff" @change="onBrandChange">
          <van-radio :name="3">美妆</van-radio>
          <van-radio :name="4">大健康</van-radio>
        </van-radio-group>
      </van-cell>
      <template v-if="upgradeInfo">
        <van-cell title="选择回归方式" class="title-mini" v-if="upgradeInfo.ChoiceList && upgradeInfo.ChoiceList.length > 1">
          <van-radio-group v-model="returnMethod" direction="horizontal" checked-color="#a771ff">
            <van-radio :name="1">全额回归</van-radio>
            <van-radio :name="2">保证金回归</van-radio>
          </van-radio-group>
        </van-cell>
        <template v-if="returnMethod !== 2">
          <van-cell class="discount" :value="getDiscountMoney" v-if="upgradeInfo.isShowDiscount">
            <template #title>
              旗舰店铺系统使用费<s>{{upgradeInfo.fixedFirstMoney}}元</s>{{ upgradeInfo.upgradeDiscount }}折优惠
            </template>
          </van-cell>
          <van-cell class="discount" :value="getShopSystemMoney + '元'" v-else>
            <template #title>旗舰店铺系统使用费</template>
          </van-cell>
          <van-cell :value="getDeductibleMoney + '元'">
            <template #title>
              开旗舰店可抵扣金额
              <!-- <van-icon name="question" color="#bd9bfb" size="16" @click="showMakeUpLayer = true" /> -->
            </template>
          </van-cell>

          <van-cell v-if="upgradeInfo.deductibleBondMoney">
            <template #title>
              {{ upgradeInfo.deductibleBondMoney }}
              <van-icon name="question" color="#bd9bfb" size="16" @click="showBondLayer = true" />
            </template>
          </van-cell>
          <!-- <van-cell :value="(upgradeInfo.PayFirstMoney ? upgradeInfo.PayFirstMoney / 100 : upgradeInfo.PayFirstMoney) + '元'">
            <template #title>
              店铺采购系统{{ getDeductibleMoney > 0 ? '抵扣后' : '' }}应付金额
            </template>
          </van-cell> -->
        </template>
      </template>
      <van-cell :value="getBondMoney + '元'" :border="false">
        <template #title>
          旗舰店保证金
        </template>
      </van-cell>
      <van-cell :border="false">
        <div class="apply-prompt">
          <template v-if="getPushManByBrandId">
            {{ getPushManByBrandId.agent_name
            }}店主已委托蜜蛋连锁代收取本次开店购买店铺系统的使用费，</template><span>该费用不可提现，不可申请退款。</span>
        </div>
      </van-cell>
      <van-cell class="pool-content">
        <van-checkbox v-model="agree" checked-color="#a771ff">我已阅读并同意<i @click.stop="showPoolLayer = true">《蜜蛋注册协议》</i>
        </van-checkbox>
      </van-cell>
      <div class="operate">
        <template v-if="getButtonText">
          <van-button type="primary" color="#bd9bfb" round block v-if="upgradeInfo && upgradeInfo.payType === 1" @click="sureApply">{{ getButtonText }}</van-button>
          <van-button type="primary" color="#999999" round block v-else>{{ getButtonText }}</van-button>
        </template>
        <van-button type="primary" color="#bd9bfb" round block v-else @click="sureApply">立即开店</van-button>
      </div>
    </van-cell-group>
    <mi-shop-right :brand-id="brandId" :num="giftNum" />
    <van-popup v-model="showPoolLayer" position="bottom" style="height:100%">
      <Protocol @close="closePoolLayer" />
    </van-popup>
    <div class="share-box" v-if="token && (upgradeInfo.isBlue || upgradeInfo.isPink)" @click="openAppShare">
      <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211222184702371.png" />
    </div>
    <!-- 补差规则 -->
    <van-popup v-model="showMakeUpLayer" position="bottom">
      <agio-rule :isActivityOpen="upgradeInfo && upgradeInfo.isActivityOpen" />
    </van-popup>
    <!-- 保证金介绍 -->
    <van-popup class="layer-rule" v-model="showBondLayer">
      <div class="introduce">
        <div class="cancel" @click="showBondLayer = false">
          <van-icon name="clear" color="#999" size="20" />
        </div>
        <div class="title">保证金介绍</div>
        <div class="introduce-txt">
          <span class="num">1</span>
          <div>
            <p>美妆旗舰店主和大健康旗舰店主保证金补贴后合并为<span>3000元</span>：</p>
          </div>
        </div>
        <div class="introduce-txt">
          <span class="num">2</span>
          <div>
            <p>单独申请美妆旗舰店主补贴后，需缴纳<span>1500元</span>保证金；</p>
          </div>
        </div>
        <div class="introduce-txt">
          <span class="num">3</span>
          <div>
            <p>单独申请大健康旗舰店主，需缴纳<span>1500元</span>保证金；</p>
          </div>
        </div>
        <!-- <div class="introduce-txt">
          <span class="num">2</span>
          <div>
            <p>如已有美妆旗舰店主身份，申请大健康旗舰店主时在原保证金基础上需补<span>1000元</span>保证金；</p>
            <p>如已有大健康旗舰店主身份，申请美妆旗舰店主时在原保证金基础上需补<span>2500元</span>保证金；</p>
          </div>
          </div> -->
      </div>
    </van-popup>
  </div>
</template>

<script>
import Protocol from "../../components/Brand/Protocol"
import MiShopRight from "./component/MiShopRight"
import { replaceAppToken, deliveryMessage } from "../../utils/appUtil"
import storage from "../../store/storage"
import { isEmpty } from "../../utils/public"
import { accMul } from "../../utils/handle"
import AgioRule from "../../components/Brand/AgioRule"
export default {
  name: 'applyPink',
  components: { Protocol, MiShopRight, AgioRule },
  data() {
    return {
      brandId: 3,
      agree: false,
      subPage: '',
      token: '',
      deviceId: '',
      shareUserId: '',
      pushManUserId: '',
      showPoolLayer: false,
      agentAndPushInfo: null,
      userIdentity: null,
      upgradeInfo: null,
      isShowDiscount: false,
      showMakeUpLayer: false, //补差规则
      showBondLayer: false, //保证金介绍
      shareAndPushData: null,//邀请人信息
      giftNum: '1~2', //礼包个数
      returnMethod: 1,//回归方式 1：全额回归 2：保证金回归
    }
  },
  shareUserId: 0,
  created() {
    let { brandId, token, subPage, shareUserId, deviceId, pushManUserId } = this.$route.query;
    if (brandId) {
      this.brandId = brandId == '4' ? 4 : 3;
    }
    this.subPage = subPage || '';
    this.shareUserId = shareUserId || '';
    this.pushManUserId = pushManUserId || '';
    this.deviceId = deviceId || '';
    if (token) {
      this.token = token;
      replaceAppToken(token, () => {
        this.initFun();
      })
    } else {
      this.initFun();
    }
  },
  methods: {
    initFun() {
      this.getAgencyInfo();
      this.getUserAgentInfo();
      if (this.shareUserId || this.deviceId) {
        this.getShareInfo();
      }
    },
    onBrandChange() {
      this.upgradeInfo = null;
      this.upgradeAgent();
    },
    //切换回归方式
    onReturnTypeChange() {
      this.upgradeInfo = null;
      this.upgradeAgent();
    },
    getAgencyInfo: function () {
      this.get("/TeamAgent/UpgradeAgentC/GetAgencyInfo").then(json => {
        if (json.code === 1) {
          this.agentAndPushInfo = json.response;
        }
      });
    },
    //获取分享人和采购店铺信息
    getShareInfo: function () {
      this.post("/TeamAgent/PlatformAgent/GetAgentAndPushInfo", {
        deviceId: this.deviceId, shareUserId: this.shareUserId,
        pushManUserId: this.pushManUserId, shareType: 2,
        brandId: this.brandId
      }).then(json => {
        this.shareAndPushData = json.response;
      })
    },
    //获取用户代理信息
    getUserAgentInfo: function () {
      var list = storage.get("agentInfo");
      if (!isEmpty(list)) {
        if (list.length > 0) {
          this.userIdentity = list;
          return this.getAgentAllLevel(list);
        }
      }
      this.get("/TeamAgent/AgentInfo/HomeIdentity_V2?brandid=-1").then(json => {
        if (json && json.code == 1) {
          this.userIdentity = json.response;
          this.getAgentAllLevel(json.response);
          storage.set("agentInfo", json.response);
        }
      })
    },
    getAgentAllLevel: function (list) {
      if (!list.length) return
      let isBeauty = false, isHealth = false
      list.forEach(item => {
        if (item.homelevel > 2) {
          if (item.Brandid === 3) {
            isBeauty = true;
          } else {
            isHealth = true;
          }
        }
      });
      this.shareUserId = list[0].userId
      if (isBeauty && !isHealth) {
        this.brandId = 4;
      }
      this.upgradeAgent();
    },
    //代理升级首批款信息
    upgradeAgent: function () {
      this.upgradeInfo = null;
      this.codeNum = 0;
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true })
      this.get("/TeamAgent/UpgradeAgent/UpgradeInfo?", {
        brandid: this.brandId,
        v: 6, p: 1, returnType: 1,
      }).then(({ code, response, msg }) => {
        this.codeNum = code;
        this.$toast.clear();
        if (code === 1) {
          this.shareUserId = response.userId;
          this.giftNum = response.isActivityOpen ? '1' : '1~2';
          // payType   0 = 立即申请 1 = 铺货未完成 2 = 资料审核 3 = 已成为店主
          if (response.isBlue || response.IsAgent) {
            response.payType = 3;
          } else {
            let data = response.Request;
            if (data) {
              //提交资料 0  // 充值完成 1   // 用户确认 12
              // 客服确认升级 2  -------- 客服快链到升级页面或者添加页面去操作+审核页面连接按钮
              // 审核代理 3:  // 取消升级 99
              if (data.state === 2 || data.state === 12) {
                response.payType = 2;
              } else if (data.state === 3) {
                response.payType = 3;
              } else {
                response.payType = data.paytype
              }
            } else {
              response.payType = 0;
            }
          }
          //PayFirstMoney 需要支付的
          //FirstMoney 首批款
          this.upgradeInfo = response;
          let choiceList = response.ChoiceList || [];
          if (choiceList.length === 1) {
            this.returnMethod = choiceList[0].Choice;
          }
          this.isShowDiscount = response.isShowDiscount
        } else if (code === 2) {
          this.upgradeInfo = { payErrorTip: msg, payType: 2 };
        } else {
          this.upgradeInfo = { payErrorTip: msg };
        }
      })
    },
    sureApply: function () {
      var data = this.upgradeInfo;
      if (!data.Request && !this.agree) return this.$dialog.alert({
        title: "提示",
        message: '请勾选我已阅读并同意《蜜蛋注册协议》'
      });
      let { upgradeInfo, token } = this;
      let param = '';
      if (this.brandId == 3) {
        param += "&addXmAuth=1&returnType=" + this.returnMethod;
      }
      if (this.getShareAndPushData) {
        param += "&deviceId=" + this.deviceId + "&shareUserId=" + this.shareUserId + "&pushManUserId=" + this.pushManUserId;
      }
      if (upgradeInfo.Request && upgradeInfo.Request.paytype == 1) {
        let { id } = upgradeInfo.Request
        if (token) {
          var message = {
            'action': 'jumpNativePay',
            'data': { 'applyId': id, 'type': 1 }
          };
          return deliveryMessage(message);
        }
        return window.location.href = this.userHost + "/rolorweb/html/recharge/upgrade_online.html?id=" + id
      }
      window.location.href = this.userHost + "/rolorweb/html/recharge/agent_up.html?brandId=" + this.brandId + param + '&token=' + token
    },
    //调用app分享
    openAppShare: function () {
      let info = this.upgradeInfo;
      if (!(info.isBlue || info.isPink)) return this.$dialog.alert({
        title: '提示',
        message: "亲爱的蜜蛋宝宝您暂时无法匹配成为开店的邀请人，所以无法分享噢！"
      })
      let basePath = this.baseHost + '/7999';
      let message = null;
      if (!this.isSeven) {
        message = {
          action: 'jumpNativeShare',
          data: {
            url: this.baseHost + '/#/apply/pinkShare?shareUserId=' + this.shareUserId,
            title: '开旗舰店',
            desc: '蜜蛋连锁邀请您一起来开旗舰店!',
            iconUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211229163415168.png',
            drawPath: basePath + '/Redesign/html/applyStore/onLinePoster.html?type=2&token=' + this.token
          }
        };
      } else {
        message = {
          action: 'jumpNativeShare',
          data: {
            url: basePath + '/Redesign/html/applyStore/pinkShare.html?shareUserId=' + shareUserId,
            title: '蜜蛋七周年,如“七”而至',
            desc: '限时七天,旗舰店铺系统7折开店优惠',
            iconUrl: basePath + '/Redesign/img/sevenFx.png',
            drawPath: basePath + '/recruit/html/poster.html?shareType=4&token=' + this.token
          }
        };
      }
      deliveryMessage(message);
    },
    closePoolLayer() {
      this.showPoolLayer = false;
    }
  },
  computed: {
    getDeductibleMoney: function () {
      let info = this.upgradeInfo;
      let money = 0;
      if (info) {
        if (info.payType === 2) return 0
        money = (info.MyPerformance + info.TruePushPerformance) / 100;
      }
      return money;
    },
    getBondMoney() {
      let info = this.upgradeInfo;
      if (!info) return '';
      if (info.Request) return info.Request.bondmoeny / 100;
      if (info.payType === 2) {
        return 0;
      }
      return info.BondMoney / 100
    },
    getButtonText() {
      let info = this.upgradeInfo;
      if (!info) return '';
      if (info.payErrorTip) return info.payErrorTip;
      if (info.payType === 1) return "铺货金额未完成支付，立即支付";
      if (info.payType === 2) return "资料审核中...请耐心等待";
      if (info.payType === 3) return `您已开启${this.brandId == 3 ? '美妆' : '大健康'}旗舰店`;
    },
    //根据品牌获取推荐人信息
    getPushManByBrandId: function () {
      var list = this.agentAndPushInfo;
      if (!list) return '';
      var brandId = this.brandId;
      var obj = list.find(it => {
        return it.brand_id === brandId
      });
      return obj;
    },
    //获取上级和推荐人信息
    getShareAndPushData: function () {
      let data = this.shareAndPushData;
      if (!data) return null;
      let brandId = this.brandId;
      let list = this.userIdentity || [];
      if (!list.length) return null;
      let item = list.find(it => {
        return it.Brandid === brandId
      })
      if (!item) return null;
      if (item.homelevel >= 2) return;
      let { upAgent, pushmanAgent } = data;
      if (brandId === 4) {
        upAgent.djkAgent.invitUser = pushmanAgent.djkAgent
        return upAgent.djkAgent
      } else {
        upAgent.mzAgent.invitUser = pushmanAgent.mzAgent
        return upAgent.mzAgent
      }
    },
    //获取折扣后的
    getDiscountMoney() {
      if (!this.upgradeInfo) return 0
      // let money = this.brandId === 3 ? 15000 : 10800;
      let money = this.upgradeInfo.fixedFirstMoney;
      return accMul(money, accMul(this.upgradeInfo.upgradeDiscount, 0.1))
    },
    //获取旗舰店铺系统金额
    getShopSystemMoney() {
      if (!this.upgradeInfo) return 0;
      // if (this.brandId === 3) {
      let type = this.returnMethod;
      let item = this.upgradeInfo.ChoiceList.find(it => it.Choice === type);
      if (item) {
        return item.ZCFirstMoney / 100
      }
      // }
      // return 7500
    },
    //获取店铺采购系统应付金额 
    getShopPayableMoney() {
      if (!this.upgradeInfo) return 0;
      let type = this.returnMethod;
      let item = this.upgradeInfo.ChoiceList.find(it => it.Choice === type);
      if (item) {
        return item.OldPayFirstMoney / 100
      }
      return 0
    }
  }
}
</script>
<style lang='scss' scoped>
@import '../../assets/style/applyStore';

.layer-rule {
  border-radius: 8px;
}

.van-cell {
  /deep/.van-cell__title {
    flex: 2;
  }

  &.discount {
    background-color: #e5d7ff;

    .van-cell__title,
    .van-cell__value {
      color: #904cff !important;
    }
  }

  &.title-mini {
    /deep/.van-cell__title {
      flex: 1;
    }

    /deep/.van-cell__value {
      flex: 2;

      .van-radio-group {
        justify-content: flex-end;

        .van-radio {
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.introduce {
  width: 260px;
  padding: 15px 25px 15px 15px;
  background: white;
  border-radius: 10px;
  position: relative;

  .cancel {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .title {
    text-align: center;
    font-size: 16px;
    color: #666666;
    margin-bottom: 10px;
  }

  .introduce-txt {
    .num {
      width: 15px;
      height: 15px;
      background: #ae81ff;
      text-align: center;
      color: white;
      border-radius: 50%;
      line-height: 15px;
      float: left;
      margin-top: 2px;
    }

    div {
      padding-left: 20px;

      p {
        color: #666666;
        font-size: 12px;
        text-align: justify;
        margin-bottom: 5px;

        span {
          color: #ae81ff;
        }
      }
    }
  }
}

.superior-info {
  padding: 11px 10%;

  .cus-content {
    display: flex;
    border-radius: 8px;
    padding: 11px 15px;
    align-items: center;
    background-color: #f8f8f8;

    > img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 6px;
    }

    .user {
      text-align: left;

      img {
        width: 50px;
        display: inline-block;
        vertical-align: middle;
      }

      .phone {
        padding-top: 5px;

        span {
          color: #999;
          font-size: 11px;
        }
      }
    }
  }
}

@media screen and (max-width: 361px) {
  .mi-content {
    .superior-info {
      padding: 11px 6%;
    }
  }
}
</style>